import { FaGithub, FaLinkedin, FaMediumM, FaGoogle } from 'react-icons/fa';

const content = {
  title: 'About.',
  subtitle: 'You can reach me at:',
  links: [
    {
      to: 'https://github.com/BaileyMcKelway',
      text: 'github',
      Icon: FaGithub,
      newTab: true,
    },
    {
      to: 'https://www.linkedin.com/in/bailey-mckelway/',
      text: 'linkedIn',
      Icon: FaLinkedin,
      newTab: true,
    },
    {
      to: 'https://medium.com/@bjlmckelway',
      text: 'medium',
      Icon: FaMediumM,
      newTab: true,
    },
    {
      to: 'mailto: bjlmckelway@gmail.com',
      text: 'email',
      Icon: FaGoogle,
      newTab: true,
    },
  ],
};

export default content;
