import { FaGithub } from 'react-icons/fa';

const content = [
  {
    title: 'orange',
    image: 'https://i.imgur.com/ouQxBa1.png',
    text: `A Google Chrome Extension that uses voice
    recognition to control elements on any webpage. What makes orange different from other vocal recognition apps is that it allows the user to record their own voice commands and actions.`,
    technologies: 'Javascript, HTML5, CSS, Chrome API, Web Speech API',
    link: [
      {
        to: 'https://github.com/BaileyMcKelway/orange',
        text: 'github',
        Icon: FaGithub,
        newTab: true,
      },
    ],
  },
  {
    title: 'Hookify',
    image: 'https://react-hookify.herokuapp.com/reacthooks.png',
    text: `Command line tool that converts React class
    components into functional component with Hooks. An easy way to refactor outdated code and a useful tool to learn how React hooks work.`,
    technologies: 'Javascript, Regex',
    link: [
      {
        to: 'https://github.com/DigitalTranslate/ReactHookify',
        text: 'github',
        Icon: FaGithub,
        newTab: true,
      },
    ],
  },
  {
    title: 'live-stream-radio',
    image: 'https://i.imgur.com/Usdfr2x.png',
    text: `Open source project that allows the user to stream audio files to any platform creating
      a 24/7 live streaming radio. I worked on a front-end client that accesses the streams API improving functionality and the user experience.`,
    technologies: 'React, Redux, Node.js, Express',
    link: [
      {
        to: 'https://github.com/BaileyMcKelway/live-stream-radio-api-frontend',
        text: 'github',
        Icon: FaGithub,
        newTab: true,
      },
    ],
  },
];

export default content;
