import React from 'react';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import { useSpring, animated } from 'react-spring';

import content from './content';

const { text } = content;
const calc = (x, y) => [
  -(y - window.innerHeight / 2) / 40,
  (x - window.innerWidth / 2) / 40,
  1.1,
];
const trans = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const Welcome = () => {
  const [props, set] = useSpring(() => ({
    xys: [0.5, 0.5, 1],
    config: { mass: 5, tension: 350, friction: 150 },
  }));
  return (
    <div id='welcome'>
      <animated.div
        className='card'
        onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
        onMouseLeave={() => set({ xys: [0, 0, 1] })}
        style={{ transform: props.xys.interpolate(trans) }}>
        <Fade in timeout={1500}>
          <Typography variant='h1' color='textPrimary'>
            {text}
          </Typography>
        </Fade>
      </animated.div>
    </div>
  );
};

export default Welcome;
